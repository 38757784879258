import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';

export const ChatEmpty: FunctionComponent = () => {
  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();

  return (
    <div className={styles['chat__empty']}>
      <h4>
        {currentUser && isBroadcaster
          ? 'Chat about your live audio'
          : "Like what you're hearing?"}
      </h4>
      <p>
        {currentUser && isBroadcaster
          ? 'Tell visitors what they’re\n listening to, say hello 👋'
          : 'Send a heart, say hello 👋'}
      </p>
    </div>
  );
};
