import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { Event } from 'features/events/eventsSlice';
import { isEqual } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { getState } from 'store';

export interface EventModel extends Event {
  isUpcoming(): boolean;
  durationToEvent(): Duration;
  isIn24hrs(): boolean;
  isVisibleOnDashboard(): boolean;
  isOnlyVisibleToCreator(): boolean;
  isOverdue(): boolean;
  parsedStartsAt?: DateTime | null;
  parsedStartedAt?: DateTime | null;
  parsedLocalStartsAt?: DateTime | null;
  parsedLocalStartedAt?: DateTime | null;
  parsedEndsAt?: DateTime | null;
  parsedEndedAt?: DateTime | null;
  parsedLocalEndsAt?: DateTime | null;
  parsedLocalEndedAt?: DateTime | null;
  isPrivate(): boolean;
  isActive(): boolean;
  isLive(): boolean;
  isEnded(): boolean;
  isListed(): boolean;
  isUpcomingOrLive(): boolean;
  isAdhoc(): boolean;
  isFallback(): boolean;
  inner: Event;
}

export const compareEvents = (
  event1: EventModel | null,
  event2: EventModel | null
): boolean => {
  if (!event1 && !event2) {
    return isEqual(event1, event2);
  }

  return isEqual(event1?.inner, event2?.inner);
};

export const compareByDate = (
  event1: EventModel,
  event2: EventModel
): number => {
  if (!event1.parsedStartsAt && !event2.parsedStartsAt) {
    return 0;
  }

  if (!event1.parsedStartsAt) {
    return -1;
  }

  if (!event2.parsedStartsAt) {
    return 1;
  }

  if (event1.parsedStartsAt > event2.parsedStartsAt) {
    return 1;
  }

  if (event1.parsedStartsAt < event2.parsedStartsAt) {
    return -1;
  }

  return 0;
};

export const compareByFallback = (
  event1: EventModel,
  event2: EventModel
): number => {
  return Number(event1.fallback) - Number(event2.fallback);
};

export const EventModel = (event: Event): EventModel => {
  const parsedAttrs: Partial<EventModel> = {};

  if (event.startsAt) {
    parsedAttrs.parsedStartsAt = DateTime.fromISO(event.startsAt).setZone(
      event.timezone
    );

    parsedAttrs.parsedLocalStartsAt = DateTime.fromISO(event.startsAt);
  }

  if (event.startedAt) {
    parsedAttrs.parsedStartedAt = DateTime.fromISO(event.startedAt).setZone(
      event.timezone
    );

    parsedAttrs.parsedLocalStartedAt = DateTime.fromISO(event.startedAt);
  }

  if (event.endsAt) {
    parsedAttrs.parsedEndsAt = DateTime.fromISO(event.endsAt).setZone(
      event.timezone
    );

    parsedAttrs.parsedLocalEndsAt = DateTime.fromISO(event.endsAt);
  }

  if (event.endedAt) {
    parsedAttrs.parsedEndedAt = DateTime.fromISO(event.endedAt).setZone(
      event.timezone
    );

    parsedAttrs.parsedLocalEndedAt = DateTime.fromISO(event.endedAt);
  }

  return {
    ...parsedAttrs,
    ...event,
    inner: event,
    isUpcoming() {
      if (!this.parsedEndsAt || this.isLive()) return false;

      const nowInTimezone = DateTime.now().setZone(event.timezone);
      return this.parsedEndsAt > nowInTimezone;
    },

    isListed() {
      if (this.inner.accessLevel === 'public') return true;
      if (this.inner.accessLevel === 'unlisted') return false;
      if (this.inner.accessLevel === 'only_me') return false;

      return false;
    },

    isIn24hrs() {
      const cutOff = DateTime.now().setZone(event.timezone).plus({ days: 1 });

      return (
        !this.isLive() && !!this.parsedStartsAt && this.parsedStartsAt < cutOff
      );
    },

    isOnlyVisibleToCreator() {
      const {
        currentUser: { me }
      } = getState();
      return (
        this.inner.accessLevel === 'only_me' &&
        me?.id === this.inner.broadcasterId
      );
    },

    isVisibleOnDashboard() {
      const cutOff = DateTime.now().setZone(event.timezone).plus({ days: 2 });

      return (
        !this.isLive() && !!this.parsedStartsAt && this.parsedStartsAt < cutOff
      );
    },

    durationToEvent() {
      if (!this.parsedStartsAt) return Duration.fromObject({ seconds: 0 });
      if (!this.isUpcoming()) return Duration.fromMillis(0);

      const nowInTimezone = DateTime.now().setZone(event.timezone);

      return this.parsedStartsAt.diff(nowInTimezone);
    },

    isOverdue() {
      if (!this.parsedStartsAt) return false;

      const nowInTimezone = DateTime.now().setZone(event.timezone);

      return this.parsedStartsAt < nowInTimezone && !this.isLive();
    },

    isPrivate() {
      return !!this.accesscodeEnabled;
    },

    isActive() {
      return !!this.active;
    },

    isLive() {
      const {
        broadcasts: { broadcasts }
      } = getState();

      const broadcast = broadcasts.find(
        (broadcastEl: Broadcast) => broadcastEl.eventId === this.id
      );

      return this.isActive() && broadcast?.isLive === true;
    },

    isUpcomingOrLive() {
      return this.isUpcoming() || this.isLive();
    },

    isEnded() {
      return !!this.endedAt;
    },

    isAdhoc() {
      return !!this.adhoc;
    },

    isFallback() {
      return !!this.fallback;
    }
  };
};
