import React, { FunctionComponent, useContext } from 'react';
import styles from './styles.module.scss';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { PoweredBy } from 'components/PoweredBy';

export const ChannelFooter: FunctionComponent = () => {
  const { isNativeAppWebview } = useContext(UserAgentContext);

  if (isNativeAppWebview) {
    return null;
  }

  return (
    <footer className={styles['mixlr-footer']}>
      <PoweredBy />
    </footer>
  );
};
