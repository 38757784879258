import React, { FunctionComponent } from 'react';
import { useDevKeyListener } from './hooks';
import { Wrapper, Title, Button } from './components';
import {
  createLiveEvent,
  stopCurrentBroadcast,
  createScheduled,
  createManyEvents,
  createManyRecordings,
  stopEvent,
  stopAudioStream,
  disconnectAudioStream
} from './stateHelpers';
import { useAudio } from 'features/audio/audio';
import { useCurrentEvent } from 'features/events/eventHooks';
import { broadcastForEventSelector } from 'features/broadcast/selectors';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { DateTime } from 'luxon';

export const StateTester: FunctionComponent = () => {
  const enabled = useDevKeyListener();
  const dispatch = useAppDispatch();
  const audioContext = useAudio();
  const currentEvent = useCurrentEvent();
  const broadcast = useAppSelector((state) =>
    broadcastForEventSelector(state, currentEvent?.id)
  );
  const channelId = useAppSelector(
    (state) => state.channel.channelDetails?.ownerId
  );

  if (!enabled) {
    return null;
  }
  return (
    <Wrapper>
      <Title>State Tester</Title>
      <p>CTRL+ALT+D to show/hide</p>
      <Button
        inline={true}
        onClick={createLiveEvent(dispatch, {
          broadcasterId: channelId
        })}
      >
        Create Live Scheduled
      </Button>

      <Button
        inline={true}
        onClick={createLiveEvent(dispatch, {
          adhoc: true,
          startsAt: null,
          endsAt: null,
          broadcasterId: channelId
        })}
      >
        Create Live Adhoc
      </Button>

      <Button
        inline={true}
        onClick={createLiveEvent(
          dispatch,
          {
            broadcasterId: channelId
          },
          {
            started_at: DateTime.now().minus({ days: 1, hours: 8 }).toISO()
          }
        )}
      >
        Create Live Scheduled + 24hrs
      </Button>

      <Button
        inline={true}
        onClick={createScheduled(dispatch, { broadcasterId: channelId })}
      >
        Create Overdue Scheduled
      </Button>

      <Button
        inline={true}
        onClick={createManyEvents(dispatch, 100, { broadcasterId: channelId })}
      >
        Create many events
      </Button>

      <Button inline={true} onClick={createManyRecordings(dispatch, 100)}>
        Create many recordings
      </Button>

      <Button inline={true} onClick={createManyRecordings(dispatch, 100)}>
        Create non-live channel
      </Button>

      {currentEvent && (
        <>
          <Button inline={true} onClick={stopEvent(dispatch, currentEvent)}>
            [Hub] Stop Event
          </Button>

          {broadcast && (
            <Button
              inline={true}
              onClick={stopCurrentBroadcast(dispatch, broadcast.uid)}
            >
              [Hub] Stop Broadcast
            </Button>
          )}

          <Button
            inline={true}
            onClick={stopAudioStream(dispatch, audioContext)}
          >
            Stop Audio Stream
          </Button>

          <Button
            inline={true}
            onClick={disconnectAudioStream(dispatch, audioContext)}
          >
            Disconnect Audio Stream
          </Button>
        </>
      )}
    </Wrapper>
  );
};
