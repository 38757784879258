import { useEffect, useState } from 'react';

export const useDevKeyListener = (): boolean => {
  const [enabled, setEnabled] = useState<boolean>(false);

  const detectFunction = (event: KeyboardEvent) => {
    if (event.code === 'KeyD' && event.ctrlKey && event.altKey) {
      setEnabled(!enabled);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', detectFunction, false);

    return () => {
      document.removeEventListener('keydown', detectFunction, false);
    };
  });

  return enabled;
};
