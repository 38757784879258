import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ReactComponent as MixlrLogo } from 'images/mixlr-logo.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useLocation, matchPath } from 'react-router-dom';

export const PoweredBy: FunctionComponent = () => {
  const url = process.env.REACT_APP_WEBSITE_ENDPOINT;
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const liveStreamingEnabled = useSelector(
    (state: RootState) => state.channel.channelDetails?.liveStreamingEnabled
  );
  const { pathname } = useLocation();
  const isRecordingPath = matchPath('/recordings/:id', pathname);

  if ((isRecordingPath && liveStreamingEnabled) || isNativeAppWebview) {
    return null;
  }

  return (
    <div
      className={classNames(styles['powered-by'], {
        [styles['powered-by--non-live']]: !liveStreamingEnabled
      })}
    >
      <a
        href={`${url}?source=${window.location.origin}`}
        className={styles['powered-by__logo']}
        target="_blank"
        rel="noreferrer"
      >
        Powered by <MixlrLogo />
      </a>
    </div>
  );
};
