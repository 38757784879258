import React, { FunctionComponent, useEffect } from 'react';
import { Event } from 'features/events/Event';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import { Channel } from 'features/channel/Channel';
import { ChannelEmbed } from 'features/channel/components/ChannelEmbed';
import { ChannelHome } from 'features/channel/components/ChannelHome';
import { ChannelAbout } from 'features/channel/components/ChannelAbout';
import { ChannelEvents } from 'features/channel/components/ChannelEvents';
import { ChannelNetwork } from 'features/channel/components/ChannelNetwork';
import { ChannelRecordings } from 'features/channel/components/ChannelRecordings';
import { AnimatePresence } from 'framer-motion';
import { Error404 } from 'components/ErrorPages/Error404';
import { ErrorEmbed } from 'components/ErrorPages/ErrorEmbed';
import { Recording } from 'features/recordings/Recording';
import { useBannedChannelChecker } from 'hooks/useBannedChannelChecker';
import { useBackButtonListener } from 'hooks/useBackButtonListener';
import { ChannelCollections } from 'features/channel/components/ChannelCollections';
import { Collection } from 'features/collections/Components/Collection';
import { useAppSelector } from 'hooks/redux';
import { ErrorDeleted } from 'components/ErrorPages/ErrorDeleted';
import { ChannelChat } from 'features/channel/components/ChannelChat';
import { ChannelCrowd } from 'features/channel/components/ChannelCrowd';

export const AnimatedRouteSwitch: FunctionComponent = () => {
  const location = useLocation();
  const liveStreamingEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.liveStreamingEnabled
  );
  const channel = useAppSelector((state) => state.channel.channelDetails);

  useBackButtonListener();
  useBannedChannelChecker();

  useEffect(() => {
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);

  return (
    <AnimatePresence initial={false}>
      <Routes location={location} key={location.pathname}>
        {liveStreamingEnabled ? (
          <>
            <Route element={<Channel />}>
              <Route path="/" element={<ChannelHome />} />
              {channel?.networkAvailable && (
                <Route path="/network" element={<ChannelNetwork />} />
              )}
              <Route path="/about" element={<ChannelAbout />} />
              <Route path="/events" element={<ChannelEvents />} />
              <Route path="/recordings" element={<ChannelRecordings />} />
              <Route
                path="/recordings/page/:page"
                element={<ChannelRecordings />}
              />
              <Route path="/collections" element={<ChannelCollections />} />
              <Route
                path="/collections/page/:page"
                element={<ChannelCollections />}
              />
              <Route
                path="/collections/deleted"
                element={<ErrorDeleted type={'recording'} />}
              />
              <Route
                path="/collections/not-found"
                element={<ErrorEmbed type={'recording'} />}
              />
              <Route path="/collections/:id" element={<Collection />} />
              <Route
                path="/collections/:id/page/:page"
                element={<Collection />}
              />
            </Route>
            <Route path="/chat" element={<ChannelChat />} />
            <Route path="/crowd" element={<ChannelCrowd />} />
            <Route path="/embed" element={<ChannelEmbed />} />
            <Route path="/events/:id" element={<Event />} />
            <Route
              path="/events/deleted"
              element={<ErrorDeleted type={'event'} />}
            />
          </>
        ) : (
          <Route element={<Channel />}>
            <Route path="/" element={<ChannelRecordings />} />
            <Route path="/page/:page" element={<ChannelRecordings />} />
            <Route path="/collections" element={<ChannelCollections />} />
            <Route
              path="/collections/page/:page"
              element={<ChannelCollections />}
            />
            <Route
              path="/collections/not-found"
              element={<ErrorEmbed type={'recording'} />}
            />
            <Route
              path="/collections/deleted"
              element={<ErrorDeleted type={'recording'} />}
            />
            <Route path="/collections/:id" element={<Collection />} />
            <Route
              path="/collections/:id/page/:page"
              element={<Collection />}
            />
          </Route>
        )}
        <Route
          path="/recordings/not-found"
          element={<ErrorEmbed type={'recording'} />}
        />
        <Route
          path="/recordings/deleted"
          element={<ErrorDeleted type={'recording'} />}
        />
        <Route path="/recordings/:id" element={<Recording />}>
          <Route path="/recordings/:id/embed" element={<Recording />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </AnimatePresence>
  );
};
