import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Crowd } from 'features/crowd/Crowd';
import { usePageLoaded } from 'hooks/usePageLoaded';
import { SEOHeaderTags } from 'components/SEO/SEOHeaderTags';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { Error404 } from 'components/ErrorPages/Error404';
import { WebSocketContext } from 'features/hub/hub';
import { useAppSelector } from 'hooks/redux';

export const ChannelCrowd: FunctionComponent = () => {
  const { isNativeAppWebview, isEmbed } = useContext(UserAgentContext);
  const isHubConnected = useAppSelector((state) => state.hub.isHubConnected);
  const isHubCrowdConnected = useAppSelector(
    (state) => state.hub.isHubCrowdConnected
  );
  const { joinCrowd } = useContext(WebSocketContext);

  usePageLoaded();

  useEffect(() => {
    if (isHubConnected && !isHubCrowdConnected) {
      joinCrowd();
    }
  }, [joinCrowd, isHubConnected, isHubCrowdConnected]);

  if (isNativeAppWebview || isEmbed) {
    return (
      <>
        <SEOHeaderTags title={'Crowd'} description={''} image={''} url={''} />
        <Crowd fullScreen={true} />
      </>
    );
  }

  return <Error404 />;
};
