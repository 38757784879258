import {
  setHasNewComments,
  setCurrentUserSubmittedComment
} from 'features/chat/chatSlice';
import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { AnimatePresence, motion } from 'framer-motion';
import { formatNumber } from 'utilities/formatters';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { Crowd } from 'features/crowd/Crowd';
import { usePageVisibility } from 'hooks/usePageVisibility';
import { useSlidePanelForCrowd } from 'features/chat/chatHooks';

interface ChatScrollWrapperProps {
  children: React.ReactNode;
}

export const ChatScrollWrapper: FunctionComponent<ChatScrollWrapperProps> = ({
  children
}) => {
  const hasNewComments = useSelector(
    (state: RootState) => state.chat.hasNewComments
  );
  const newCommentCount = useSelector(
    (state: RootState) => state.chat.newCommentCount
  );
  const currentUserSubmittedComment = useSelector(
    (state: RootState) => state.chat.currentUserSubmittedComment
  );
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [scrollRefVisible, setScrollRefVisible] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { isDACreatorApp } = useContext(UserAgentContext);
  const isPageVisible = usePageVisibility();
  const slidePanelForCrowd = useSlidePanelForCrowd();

  const daScrollTop = (el: HTMLElement) => {
    return el.clientHeight - el.scrollHeight;
  };

  const scrollToBottom = useCallback(() => {
    if (scrollWrapperRef.current) {
      dispatch(setCurrentUserSubmittedComment(false));
      setShowScrollButton(false);
      dispatch(setHasNewComments(false));
      scrollWrapperRef.current.scrollTo({
        top: isDACreatorApp
          ? Math.abs(daScrollTop(scrollWrapperRef.current))
          : 0,
        behavior: 'smooth'
      });
    }
  }, [setShowScrollButton, dispatch, isDACreatorApp]);

  useEffect(
    function handleChatScrollEvents() {
      if (!scrollRefVisible || !isPageVisible) {
        return;
      }

      const onScroll = (event: Event) => {
        let scrollTop = (event.target as HTMLElement).scrollTop;

        // DA does not support flex-direction: column-reverse; properly
        // so scrollTop is not the bottom!
        if (isDACreatorApp && scrollWrapperRef.current) {
          scrollTop = scrollTop + daScrollTop(scrollWrapperRef.current);
        }

        setScrollOffset(Math.round(scrollTop));

        if (scrollTop === 0) {
          dispatch(setHasNewComments(false));
          setShowScrollButton(false);
        }
      };

      if (scrollWrapperRef.current) {
        scrollWrapperRef.current.addEventListener('scroll', onScroll, {
          passive: true
        });
      }
      return () => {
        if (scrollWrapperRef.current) {
          scrollWrapperRef.current.removeEventListener('scroll', onScroll);
        }
      };
    },
    [scrollRefVisible, dispatch, isDACreatorApp, isPageVisible]
  );

  useEffect(
    function showScrollButton() {
      if (hasNewComments && scrollOffset === 0 && isPageVisible) {
        dispatch(setHasNewComments(false));
        setShowScrollButton(false);
      } else {
        setShowScrollButton(
          !currentUserSubmittedComment && hasNewComments && scrollOffset !== 0
        );
      }
    },
    [
      dispatch,
      scrollOffset,
      setShowScrollButton,
      hasNewComments,
      currentUserSubmittedComment,
      isPageVisible
    ]
  );

  useEffect(
    function currentUserComment() {
      if (currentUserSubmittedComment && scrollOffset !== 0 && isPageVisible) {
        scrollToBottom();
      }
    },
    [currentUserSubmittedComment, scrollToBottom, scrollOffset, isPageVisible]
  );

  const buttonCountMsg = `${formatNumber(newCommentCount)} New comment${
    newCommentCount === 1 ? '' : 's'
  }`;

  return (
    <div className="tw-flex tw-flex-1 tw-flex-col tw-h-full tw-overflow-hidden tw-relative sm:tw-rounded-lg">
      <div
        ref={(el) => {
          scrollWrapperRef.current = el;
          setScrollRefVisible(!!el);
        }}
        id="chat-scroll"
        className={styles['chat__scroll']}
      >
        {children}
      </div>
      <AnimatePresence>
        {showScrollButton && (
          <motion.div
            className={styles['chat__scroll-button']}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <ButtonLink
              label={buttonCountMsg}
              onClick={scrollToBottom}
              icon={'arrowDown'}
              iconSize={12}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!slidePanelForCrowd && <Crowd />}
    </div>
  );
};
