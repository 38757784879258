import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useRef,
  useState
} from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FullImage } from 'components/Images/FullImage';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';
import tinycolor from 'tinycolor2';
import { Media } from 'api/v3/types/media';
import { useNativefullScreenChat } from 'features/chat/chatHooks';

interface FullScreenWrapperProps {
  children?: React.ReactNode;
  artwork?: Media;
  artworkMode?: string | undefined;
  updatedTimestamp?: string;
  color?: string;
  type: 'event' | 'recording' | 'private';
}

export const FullScreenWrapper: FunctionComponent<FullScreenWrapperProps> = (
  props
) => {
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);
  const { postMessage } = useNativeEvents();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [themeContrast, setThemeContrast] = useState<string>('light-contrast');
  const nativefullScreenChat = useNativefullScreenChat();

  const hasImage =
    props.artworkMode !== 'theme' ||
    !props.artwork?.image.large?.includes('fallback');
  // TODO - We only need the blur once new layout
  // is implemented in the native listener app
  const artworkUrl =
    (isListenerAppWebview && !nativefullScreenChat) || isEmbed
      ? props.artwork?.image.large
      : props.artwork?.image_blur.large;

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        hasImage: hasImage
      });
    }
  }, [hasImage, isListenerAppWebview, postMessage]);

  useEffect(
    function overrideThemeColor() {
      if (props.color && wrapperRef.current) {
        const color = tinycolor(props.color);
        const themeColorIsLight = color.getLuminance() > 0.5;
        const colorAsRGB = color.toRgb();
        wrapperRef.current.style.setProperty(
          '--color__primary',
          `${colorAsRGB.r}, ${colorAsRGB.g}, ${colorAsRGB.b}`
        );
        setThemeContrast(
          themeColorIsLight ? 'light-contrast' : 'dark-contrast'
        );
      }
    },
    [props.color]
  );

  return (
    <div
      ref={wrapperRef}
      className={classNames(
        styles['full-screen__wrapper'],
        themeContrast,
        'wrapper',
        {
          [styles['full-screen__wrapper--image']]: hasImage,
          'has-image': hasImage
        }
      )}
    >
      <FullImage
        artworkUrl={artworkUrl || null}
        artworkMode={props.artworkMode}
        updatedTimestamp={props.updatedTimestamp}
        type={props.type}
        hideOverlay={(isListenerAppWebview && !nativefullScreenChat) || isEmbed}
      />
      {props.children}
    </div>
  );
};
