import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';

interface PillProps {
  label: any;
  type: string;
  icon?: string;
  hideLabel?: boolean;
}

export const Pill: FunctionComponent<PillProps> = (props) => {
  return (
    <div
      className={classNames(
        `tw-inline-flex tw-gap-2 tw-items-center tw-justify-center tw-text-base [.ios_&]:tw-text-xs tw-font-bold tw-rounded-full tw-h-[24px] ${
          props.hideLabel ? 'tw-w-[24px]' : 'tw-px-3 [.ios_&]:tw-px-2'
        }`,
        {
          'tw-bg-mixlr-red tw-text-white': props.type === 'live',
          'tw-bg-white/20 tw-text-white': props.type === 'fallback',
          'tw-bg-theme-color tw-text-white [.light-contrast_&]:text-black [.has-image_&]:!tw-text-white':
            props.type === 'success' || props.type === 'theme',
          'tw-bg-amber-400 tw-text-white': props.type === 'notify',
          'tw-bg-red-500 tw-text-white': props.type === 'error',
          'tw-text-white group-[.light-contrast]/card:tw-bg-black/20 group-[.light-contrast]/card:tw-text-black group-[.dark-contrast]/card:tw-bg-white/20 group-[.dark-contrast]/card:tw-text-white':
            props.type === 'default'
        }
      )}
    >
      {props.icon && (
        <Icon
          hidden={true}
          icon={props.icon}
          label={props.label}
          height={12}
          width={12}
        />
      )}
      {props.label}
    </div>
  );
};
