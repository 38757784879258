import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAudioUrl } from 'features/audio/audioSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useEventFetcher } from 'features/events/eventHooks';
import { Loader } from 'components/Loader';
import { WebSocketContext } from 'features/hub/hub';
import { PrivateEvent } from './PrivateEvent';
import { PageAnimationFullScreen } from 'components/LayoutAnimations/PageAnimationFullScreen';
import { EventHeaderTags } from './EventHeaderTags';
import { EventWrapper } from './EventWrapper';
import { EventHeader } from './EventHeader';
import { EventContent } from './EventContent';
import { EventEmbed } from './EventEmbed';
import { EventCreator } from './EventCreator';
import { loadEvent } from 'thunks/navigationThunks';
import { useLocation } from 'react-router-dom';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAppSelector } from 'hooks/redux';
import { v4 as uuidv4 } from 'uuid';
import {
  currentEventBroadcastSelector,
  currentBroadcastFinishedSelector
} from 'features/broadcast/selectors';
import { EventDeepLinkBanner } from './components/EventDeepLinkBanner';
import { useAudioMediaSession } from 'features/audio/audioHooks';

export const Event: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );
  const currentBroadcastUrl = useAppSelector(
    currentEventBroadcastSelector
  )?.url;
  const currentBroadcastUID = useAppSelector(
    currentEventBroadcastSelector
  )?.uid;

  const { event, privateEvent } = useEventFetcher(id);

  const { postMessage } = useNativeEvents();
  const navigate = useNavigate();

  const location = useLocation() as any;
  const minimiseUrl = (location.state && location.state.prevPath) || '/';
  const {
    isListenerAppWebview,
    isEmbed,
    isCreatorAppWebview,
    isNativeAppWebview
  } = useContext(UserAgentContext);

  const { subscribeToBroadcast, joinCrowd, leaveCrowd } =
    useContext(WebSocketContext);
  const isHubConnected = useSelector(
    (state: RootState) => state.hub.isHubConnected
  );
  const isHubCrowdConnected = useSelector(
    (state: RootState) => state.hub.isHubCrowdConnected
  );
  const isHubBroadcastConnected = useSelector(
    (state: RootState) => state.hub.isHubBroadcastConnected
  );
  const currentBroadcastFinished = useSelector(
    currentBroadcastFinishedSelector
  );
  const eventMinimised = useAppSelector((state) => state.events.eventMinimised);

  useAudioMediaSession(event?.title, event?.artwork.image.medium, 'event');

  useEffect(
    function listenerAppPostBroadcastFinished() {
      if (isListenerAppWebview) {
        postMessage({
          currentBroadcastFinished: currentBroadcastFinished
        });
      }
    },
    [currentBroadcastFinished, postMessage, isListenerAppWebview]
  );

  useEffect(
    function listenerAppPostBroadcastUrl() {
      if (
        isListenerAppWebview &&
        event &&
        currentBroadcastUrl &&
        !currentBroadcastFinished
      ) {
        const audioUrl =
          process.env.REACT_APP_ENV_NAME === 'development'
            ? 'https://radiomeuh2.ice.infomaniak.ch/radiomeuh2-128.mp3'
            : currentBroadcastUrl;
        postMessage({
          audioUrl: audioUrl,
          audioArtworkUrl: event.artwork.image.medium,
          audioId: event.id,
          audioOwnerId: event.broadcasterId,
          audioSessionId: uuidv4(),
          audioTitle: event.title
        });
      }
    },
    [
      isListenerAppWebview,
      currentBroadcastUrl,
      currentBroadcastFinished,
      postMessage,
      event
    ]
  );

  useEffect(() => {
    dispatch(loadEvent(id || null));
  }, [id, dispatch]);

  useEffect(() => {
    if (currentBroadcastUrl) {
      dispatch(setAudioUrl(currentBroadcastUrl));
    }
  }, [currentBroadcastUrl, dispatch]);

  useEffect(() => {
    if (isHubConnected && !isHubCrowdConnected && !eventMinimised) {
      joinCrowd();
    }
  }, [joinCrowd, isHubConnected, isHubCrowdConnected, eventMinimised]);

  useEffect(() => {
    if (eventMinimised) {
      leaveCrowd();
    }
  }, [leaveCrowd, eventMinimised]);

  useEffect(() => {
    if (
      isHubConnected &&
      !isHubBroadcastConnected &&
      currentBroadcastUID &&
      !currentBroadcastFinished
    ) {
      subscribeToBroadcast(currentBroadcastUID);
    }
  }, [
    currentBroadcastUID,
    subscribeToBroadcast,
    isHubConnected,
    isHubBroadcastConnected,
    currentBroadcastFinished
  ]);

  if (!event && privateEvent) {
    return (
      <PageAnimationFullScreen>
        <EventHeaderTags event={privateEvent} />
        <EventWrapper event={privateEvent}>
          <PrivateEvent event={privateEvent} />
        </EventWrapper>
      </PageAnimationFullScreen>
    );
  }

  if (!event || !channel) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  if (isEmbed) {
    return <EventEmbed event={event} />;
  }

  if (isCreatorAppWebview) {
    return <EventCreator event={event} />;
  }

  if (channel.embedOnly === true) {
    navigate('../404');
    return null;
  }

  return (
    <PageAnimationFullScreen>
      <EventHeaderTags event={event} />
      <EventWrapper event={event}>
        <EventDeepLinkBanner />
        <EventHeader minimiseUrl={minimiseUrl} />
        <EventContent event={event} />
      </EventWrapper>
    </PageAnimationFullScreen>
  );
};
