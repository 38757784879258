import { AppDispatch } from 'store';
import { Event, addEvents } from 'features/events/eventsSlice';
import {
  Recording,
  getRecordingsSuccess
} from 'features/recordings/recordingsSlice';
import { DateTime } from 'luxon';
import { queueAutoPlay } from 'features/events/thunks';
import {
  HubBroadcastStart,
  HubBroadcastState,
  HubEventPayload
} from 'features/hub/hub';
import { AudioPlayerContext } from 'features/audio/audio';
import { EventModel } from 'features/events/EventModel';
import {
  handleBroadcastFinished,
  handleEventStopped
} from 'features/hub/hubThunks';
import { mapHubBroadcastStart } from 'features/broadcast/mappers';
import { addBroadcast } from 'features/broadcast/broadcastsSlice';

export const createLiveEvent = (
  dispatch: AppDispatch,
  overrides: Partial<Event> = {},
  broadcastOverrides: Partial<HubBroadcastState> = {}
) => {
  return () => {
    const newEvent: Event = generateMockEvent(overrides);
    const eventModel = EventModel(newEvent);

    const broadcast = generateMockBroadcastStart(newEvent, broadcastOverrides);

    dispatch(addEvents([newEvent]));
    dispatch(addBroadcast(mapHubBroadcastStart(broadcast)));
    dispatch(queueAutoPlay({ event: mapEventToHubEvent(eventModel) }));
  };
};

export const createScheduled = (
  dispatch: AppDispatch,
  overrides: Partial<Event> = {}
) => {
  return () => {
    const newEvent: Event = generateMockEvent(overrides);

    dispatch(addEvents([newEvent]));
  };
};

export const createManyEvents = (
  dispatch: AppDispatch,
  count: number,
  overrides: Partial<Event> = {}
) => {
  return () => {
    const events = [];
    for (let i = 0; i < count; i += 1) {
      events.push(generateMockEvent(overrides));
    }
    dispatch(addEvents(events));
  };
};

export const createManyRecordings = (dispatch: AppDispatch, count: number) => {
  return () => {
    const recordings = [];
    for (let i = 0; i < count; i += 1) {
      recordings.push(generateMockRecording());
    }
    dispatch(getRecordingsSuccess(recordings));
  };
};

export const stopCurrentBroadcast = (
  dispatch: AppDispatch,
  broadcastUid: string
) => {
  return () => {
    dispatch(
      handleBroadcastFinished({
        channel: `dev;broadcast;${broadcastUid}`,
        data: {},
        type: 'finished'
      })
    );
  };
};

export const stopEvent = (dispatch: AppDispatch, event: EventModel) => {
  return () => {
    dispatch(
      handleEventStopped({
        event: mapEventToHubEvent(event)
      })
    );
  };
};

export const stopAudioStream = (
  dispatch: AppDispatch,
  audioContext: AudioPlayerContext
) => {
  return () => {
    audioContext.stop(true);
  };
};

export const disconnectAudioStream = (
  dispatch: AppDispatch,
  audioContext: AudioPlayerContext
) => {
  return () => {
    audioContext.disconnect();
  };
};

const mapEventToHubEvent = (event: EventModel): HubEventPayload => {
  return {
    id: parseInt(event.id),
    starts_at: event.startsAt || DateTime.now().toISO(),
    started_at: event.startedAt || DateTime.now().toISO(),
    ended_at: DateTime.now().toISO(),
    ends_at: event.endsAt || DateTime.now().toISO(),
    active: false,
    title: event.title,
    description: event.description || '',
    artwork:
      event.artworkUrl && event.artworkKey
        ? { url: event.artworkUrl, original_key: event.artworkKey }
        : {},
    artwork_mode: event.artworkMode,
    timezone: event.timezone,
    adhoc: event.adhoc,
    chat_enabled: event.chatEnabled,
    hearting_enabled: event.heartingEnabled,
    stats_visible: event.statsVisible,
    accesscode_enabled: event.accesscodeEnabled,
    access_level: event.accessLevel,
    broadcaster: { id: 1 },
    category: { id: 1 },
    url: event.legacyUrl,
    updated_at: DateTime.now().toISO().toString(),
    color: '#000000',
    media: {
      artwork: event.artwork
    },
    fallback: false
  };
};

let eventCounter = 1;

const generateMockEvent = (overrides: Partial<Event> = {}): Event => {
  const eventDefaults: Event = {
    id: (10000000 + Math.floor(Math.random() * 100000)).toString(),
    title: `Generated Mock Event ${eventCounter}`,
    description: `Generated Mock Event ${eventCounter}`,
    artworkUrl: null,
    artworkKey: null,
    artworkMode: 'channel',
    startsAt: DateTime.now().toISO(),
    endsAt: DateTime.now().plus({ hours: 1 }).toISO(),
    startedAt: DateTime.now().toISO(),
    endedAt: null,
    adhoc: false,
    timezone: 'Europe/London',
    active: true,
    accesscodeEnabled: false,
    accesscodeLink: null,
    accesscodeLinkType: null,
    accesscodeCopy: null,
    accessFailed: false,
    chatEnabled: true,
    heartingEnabled: true,
    statsVisible: true,
    broadcasterId: '1',
    legacyUrl: 'example.com/test-event',
    lastUpdatedAt: '1000',
    color: '#000000',
    accessLevel: 'public',
    lastHubEventAt: null,
    artwork: {
      image: {
        large: null,
        medium: null,
        small: null
      },
      image_blur: {
        large: null,
        medium: null,
        small: null
      },
      image_seo: {
        og_image: null
      },
      favicon: {
        x16: null,
        x32: null,
        x180: null,
        x192: null,
        x512: null
      }
    },
    fallback: false
  };

  eventCounter += 1;

  return {
    ...eventDefaults,
    ...overrides
  };
};

let recordingCounter = 1;

const generateMockRecording = (
  overrides: Partial<Recording> = {}
): Recording => {
  const defaults: Recording = {
    id: (10000000 + Math.floor(Math.random() * 100000)).toString(),
    title: `Generated Mock recording ${recordingCounter}`,
    description: 'a recording',
    categoryId: '1',
    url: 'test.mp3',
    createdAt: DateTime.now().toISO(),
    duration: 500,
    accessLevel: 'public',
    fileFormat: 'mp3',
    artworkKey: '',
    userId: '1',
    artworkUrl: 'pic.png',
    color: '#000000',
    shouldDisplayOnChannel: true,
    artwork: {
      image: {
        large: null,
        medium: null,
        small: null
      },
      image_blur: {
        large: null,
        medium: null,
        small: null
      },
      image_seo: {
        og_image: null
      },
      favicon: {
        x16: null,
        x32: null,
        x180: null,
        x192: null,
        x512: null
      }
    },
    artworkMode: 'channel'
  };

  recordingCounter += 1;

  return {
    ...defaults,
    ...overrides
  };
};

let broadcastCounter = 1;
const generateMockBroadcastStart = (
  event: Event,
  overrides: Partial<HubBroadcastState> = {}
): HubBroadcastStart => {
  const broadcastStateDefaults: HubBroadcastState = {
    uid: broadcastCounter.toString(),
    title: 'Generated Mock Broadcast',
    duration: 142,
    category_name: 'Mock',
    streams: {
      progressive: {
        url: 'https://radiomeuh2.ice.infomaniak.ch/radiomeuh2-128.mp3'
      }
    },
    listener_count: 42,
    heart_count: 11,
    started_at: DateTime.now().toISO(),
    event_id: event.id
  };

  broadcastCounter++;

  return {
    broadcast: {
      ...broadcastStateDefaults,
      ...overrides
    }
  };
};
