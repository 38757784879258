import React, { FunctionComponent } from 'react';
import { Pill } from 'components/Pill';

interface LiveNowPillProps {
  isLive?: boolean | undefined;
  isFallback?: boolean | undefined;
}

export const LiveNowPill: FunctionComponent<LiveNowPillProps> = (props) => {
  if (props.isFallback) {
    return <Pill icon={'playlist'} label={'Fallback'} type={'fallback'} />;
  }

  if (props.isLive) {
    return <Pill icon={'liveNow'} label={'Live'} type={'live'} />;
  }

  return null;
};
