import React, { FunctionComponent, ReactNode } from 'react';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';

interface CardLinkNativeProps {
  children: ReactNode;
  url: string | null;
  type: 'event' | 'recording';
}

export const CardLinkNative: FunctionComponent<CardLinkNativeProps> = ({
  children,
  url,
  type
}) => {
  const { nativeUrlSchemeLink } = useNativeEvents();
  const channel = useSelector(
    (state: RootState) => state.channel.channelDetails
  );

  return (
    <a
      href={nativeUrlSchemeLink('channel', type, {
        url: `${window.location.protocol + '//' + window.location.host + url}`,
        channel_url: window.location.origin,
        channel_name: channel?.username,
        channel_theme_color: channel?.themeColor,
        channel_logo_url: channel?.channelLogo.image.small
      })}
      className={'tw-text-black group-[.dark-contrast]/card:tw-text-white'}
    >
      {children}
    </a>
  );
};
